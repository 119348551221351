// File mapping of sections > tab > file to display
export const fileMapping = {
  "Volcano Plot": {
    Visualization: "volcano_0_dpi150.png",
    "Data Matrix": "volcano.csv",
  },
  Heatmap: {
    "Top Samples": "heatmap_1_dpi150.png",
    "All Samples": "heatmap_0_dpi150.png",
  },
  "Statistical Parametric Test": {
    Visualization: "tt_0_dpi150.png",
    "Data Matrix": "statistical_parametric_test.csv",
  },
  "Fold Change Analysis": {
    Visualization: "fc_0_dpi150.png",
    "Data Matrix": "fold_change.csv",
  },
  "Principal Component Analysis": {
    Visualization: "pca_score2d_0_dpi150.png",
    "Data Matrix": "pca_score.csv",
    "PCA Score": "pca_score.csv",
    "Group Labels": "data_normalized.csv",
    "PCA Variance": "pca_variance.csv",
  },
  "Venn-Diagram": {
    Visualization: "venn-dimensions.png",
    "Data Matrix": "venn_out_data.txt",
  },
  Normalization: {
    Visualization: "norm_0_dpi150.png",
    "Data Original": "data_original.csv",
    "Data Matrix": "data_normalized.csv",
  },
  "Input Data": "data_original.csv",
  "Result Data": "all_data.tsv",
  "Random Forest": {
    Classification: ["rf_cls_0_dpi150.png", "randomforest_confusion.csv"],
    Feature: ["rf_imp_0_dpi150.png", "randomforests_sigfeatures.csv"],
    Outlier: ["rf_outlier_0_dpi150.png"],
  },
  "GO Biological Process": {
    "Enrichment Plot": "gobp_bar.jpeg",
    "Enrichment Plot Data": "egobp.tsv",
    "Enriched terms & connected genes": "gobp_gene_network.jpeg",
    "GSEA Ridge plot": "gsebp_ridge.jpeg",
    "GSEA Ridge plot Data": "gsebp.tsv",
    "GSEA Heatmap plot": "gsebp_heat.jpeg",
    "GSEA Heatmap plot Data": "gsebp.tsv",
    "GSEA Tree cluster plot": "gsebp_tree.jpeg",
    // "GSEA Tree cluster Data": "gsecc_tree.tsv",
  },
  "GO Molecular Function": {
    "Enrichment Plot": "gomf_bar.jpeg",
    "Enrichment Plot Data": "egomf.tsv",
    "Enriched terms & connected genes": "gomf_gene_network.jpeg",
    "GSEA Ridge plot": "gsemf_ridge.jpeg",
    "GSEA Ridge plot Data": "gsemf.tsv",
    "GSEA Heatmap plot": "gsemf_heat.jpeg",
    "GSEA Heatmap plot Data": "gsemf.tsv",
    "GSEA Tree cluster plot": "gsemf_tree.jpeg",
    // "GSEA Tree cluster Data": "gsecc_tree.tsv",
  },
  "GO Cellular Component": {
    "Enrichment Plot": "gocc_bar.jpeg",
    "Enrichment Plot Data": "egocc.tsv",
    "Enriched terms & connected genes": "gocc_gene_network.jpeg",
    "GSEA Ridge plot": "gsecc_ridge.jpeg",
    "GSEA Ridge plot Data": "gsecc.tsv",
    "GSEA Heatmap plot": "gsecc_heat.jpeg",
    "GSEA Heatmap plot Data": "gsecc.tsv",
    "GSEA Tree cluster plot": "gsecc_tree.jpeg",
    // "GSEA Tree cluster Data": "gsecc_tree.tsv",
  },
  "KEGG Pathway": {
    "Enrichment Plot": "kegg_pathway_bar.jpeg",
    "Enrichment Plot Data": "kegg.tsv",
    "Enriched terms & connected genes": "kegg_gene_network.jpeg",
    "GSEA Ridge plot": "kegg_pathway_ridge.jpeg",
    "GSEA Ridge plot Data": "gsemkk.tsv",
    "GSEA Heatmap plot": "kegg_heat.jpeg",
    "GSEA Heatmap plot Data": "gsemkk.tsv",
    "GSEA Tree cluster plot": "kegg_tree.jpeg",
    "GSEA Tree cluster plot Data": "gsecc_tree.tsv",
  },
  "KEGG Module": {
    "Enrichment Plot": "kegg_pathway_bar.jpeg",
    "Enrichment Plot Data": "kegg.tsv",
    "Enriched terms & connected genes": "kegg_gene_network.jpeg",
    "GSEA Ridge plot": "kegg_pathway_ridge.jpeg",
    "GSEA Ridge plot Data": "gsemkk.tsv",
    "GSEA Heatmap plot": "kegg_heat.jpeg",
    "GSEA Heatmap plot Data": "gsemkk.tsv",
    "GSEA Tree cluster plot": "kegg_tree.jpeg",
    "GSEA Tree cluster plot Data": "gsecc_tree.tsv",
  },
};

// Mapping of tabs for each section of results page
export const sectionToTabs = {
  Heatmap: [`Top <-numbOfTopVolcanoSamples-> Samples`, "All Samples"],
  "Random Forest": ["Classification", "Feature", "Outlier"],
  "Volcano Plot": ["Visualization", "Data Matrix"],
  "Statistical Parametric Test": ["Visualization", "Data Matrix"],
  "Fold Change Analysis": ["Visualization", "Data Matrix"],
  "Principal Component Analysis": ["Visualization", "Data Matrix"],
  "Venn-Diagram": ["Visualization", "Data Matrix"],
  Normalization: ["Visualization", "Data Matrix"],
  "GO Biological Process": [
    "Enrichment Plot",
    "Enriched terms & connected genes",
    "GSEA Ridge plot",
    "GSEA Heatmap plot",
    "GSEA Tree cluster plot",
  ],
  "GO Molecular Function": [
    "Enrichment Plot",
    "Enriched terms & connected genes",
    "GSEA Ridge plot",
    "GSEA Heatmap plot",
    "GSEA Tree cluster plot",
  ],
  "GO Cellular Component": [
    "Enrichment Plot",
    "Enriched terms & connected genes",
    "GSEA Ridge plot",
    "GSEA Heatmap plot",
    "GSEA Tree cluster plot",
  ],
  "KEGG Pathway": ["Enrichment Plot", "GSEA Ridge plot", "GSEA Heatmap plot"],
  "KEGG Module": ["Enrichment Plot", "GSEA Ridge plot", "GSEA Heatmap plot"],
};

// Mapping of each download link to file name in s3
export const downloadMapping = {
  "Volcano Plot": "volcano_0_dpi150.png",
  "Volcano Data": "volcano.csv",
  "Top 25 Samples Heatmap": "heatmap_1_dpi150.png",
  "All Samples Heatmap": "heatmap_0_dpi150.png",
  "Statistical Parametric Test Plot": "tt_0_dpi150.png",
  "Statistical Parametric Test Data": "statistical_parametric_test.csv",
  "Fold Change Analysis Plot": "fc_0_dpi150.png",
  "Fold Change Analysis Data": "fold_change.csv",
  "Principal Component Analysis Plot": "pca_score2d_0_dpi150.png",
  "Principal Component Analysis Data": "pca_score.csv",
  "Venn-Diagram Plot": "venn-dimensions.png",
  "Venn-Diagram Data": "venn_out_data.txt",
  "Normalization Plot": "norm_0_dpi150.png",
  "Normalization Data": "data_normalized.csv",
  "Input Data": "data_original.csv",
  "Random Forest CLS": "rf_cls_0_dpi150.png",
  "Random Forest IMP": "rf_imp_0_dpi150.png",
  "Random Forest Outlier": "rf_outlier_0_dpi150.png",
  // "GO Biological Process": "",
  // "GO Molecular Function": "",
  // "GO Cellular Component": "",
  // "KEGG Pathway/Module": "",
  "Result Data": "all_data.tsv",
  "All Data Set": "data_set.zip",
};

export const fileNames = [
  "all_data.tsv",
  "data_normalized.csv",
  "data_original.csv",
  "data_processed.csv",
  "egobp_gene_net.tsv",
  "egobp.tsv",
  "egomf_gene_net.tsv",
  "egomf.tsv",
  "egocc_gene_net.tsv",
  "egocc.tsv",
  "kegg_gene_net.tsv",
  "fold_change.csv",
  "gsebp.tsv",
  "gsemf.tsv",
  "gsecc.tsv",
  "gsekk.tsv",
  "gsebp_tree.jpeg",
  "gsecc_tree.jpeg",
  "gsemf_tree.jpeg",
  "gsecc_tree.tsv",
  "heatmap_0_dpi150.png",
  "heatmap_1_dpi150.png",
  "kegg.tsv",
  "pca_loadings.csv",
  "pca_score.csv",
  "pca_variance.csv",
  "randomforest_confusion.csv",
  "randomforests_sigfeatures.csv",
  "rf_cls_0_dpi150.png",
  "rf_imp_0_dpi150.png",
  "rf_outlier_0_dpi150.png",
  "statistical_parametric_test.csv",
  "venn_out_data.txt",
  "volcano.csv",
  "kegg_pathway_ridge.jpeg",
  "kegg_pathway_heat.jpeg",
];

export const goKeggDict = {
  "GO Biological Process": [
    "egobp.tsv",
    "egobp_gene_net.tsv",
    "gsebp.tsv",
    "gsecc_tree.tsv",
  ],
  "GO Molecular Function": [
    "egomf.tsv",
    "egomf_gene_net.tsv",
    "gsemf.tsv",
    "gsecc_tree.tsv",
  ],
  "GO Cellular Component": [
    "egocc.tsv",
    "egocc_gene_net.tsv",
    "gsecc.tsv",
    "gsecc_tree.tsv",
  ],
  "KEGG Pathway": [
    "kegg.tsv",
    "kegg_pathway_ridge.jpeg",
    "kegg_pathway_heat.jpeg",
  ],
  "KEGG Module": ["kegg.tsv", "kegg.tsv", "kegg_module_heat.jpeg"],
};

export const option = [
  "Volcano Plot",
  "Heatmap",
  "Statistical Parametric Test",
  "Fold Change Analysis",
  "Principal Component Analysis",
  "Venn-Diagram",
  "Normalization",
  "Random Forest",
  "GO Biological Process",
  "GO Molecular Function",
  "GO Cellular Component",
  // "KEGG Pathway",
  // "KEGG Module",
  "Input Data",
  "Result Data",
  "Download",
];
